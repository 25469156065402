import { Routes, Route } from 'react-router-dom';
import useFetch from 'react-fetch-hook';
import { useState } from 'react';

import Home from './pages/Home';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Terms from './pages/Terms';
import ThankYou from './pages/ThankYou';

export const ADMIN_URL = 'https://admin.bigcityschool.online/wp-json/wp/v2';

const App = () => {
  const { isLoading, data, error } = useFetch(`${ADMIN_URL}/general_settings?slug=general-settings`);
  const [modal, setModal] = useState(false);

  if (isLoading || error) return;

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Home
            address={data[0].acf.contacts.address}
            phone={data[0].acf.contacts.phone}
            email={data[0].acf.contacts.email}
            logos={{
              header: data[0].acf.header_logo,
              footer: data[0].acf.footer_logo,
            }}
            setModal={setModal}
            modal={modal}
          />
        }
      />

      <Route
        path="/thank-you"
        element={
          <ThankYou
            address={data[0].acf.contacts.address}
            phone={data[0].acf.contacts.phone}
            email={data[0].acf.contacts.email}
            logos={{
              header: data[0].acf.header_logo,
              footer: data[0].acf.footer_logo,
            }}
            setModal={setModal}
            modal={modal}
          />
        }
      />

      <Route
        path="/privacy-policy"
        element={
          <PrivacyPolicy
            address={data[0].acf.contacts.address}
            phone={data[0].acf.contacts.phone}
            email={data[0].acf.contacts.email}
            logos={{
              header: data[0].acf.header_logo,
              footer: data[0].acf.footer_logo,
            }}
            setModal={setModal}
            modal={modal}
          />
        }
      />

      <Route
        path="/terms"
        element={
          <Terms
            address={data[0].acf.contacts.address}
            phone={data[0].acf.contacts.phone}
            email={data[0].acf.contacts.email}
            logos={{
              header: data[0].acf.header_logo,
              footer: data[0].acf.footer_logo,
            }}
            setModal={setModal}
            modal={modal}
          />
        }
      />
    </Routes>
  );
};

export default App;
