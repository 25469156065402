import { hero_content } from '../../static/_content';

import './hero.scss';

const Hero = ({ data, setModal }) => {
  const handleClickScroll = () => {
    const element = document.getElementById('pricing');
    if (element) element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <section className="hero">
      <div className="container">
        <div className="hero__inner">
          <div className="hero__content">
            <h1>{data.title}</h1>

            <p className="subtitle">{hero_content.subtitle}</p>

            {/* <p className="start_from">{data.start_from}</p> */}

            <div className="buttons">
              <button className="button blue" onClick={() => setModal(true)}>
                {hero_content.cta_button}
              </button>

              <button className="button orange" onClick={handleClickScroll}>
                {hero_content.scroll_button}
              </button>
            </div>
          </div>

          <div className="hero__image" style={{ backgroundImage: `url('${data.photo}')` }} />
        </div>
      </div>
    </section>
  );
};

export default Hero;
