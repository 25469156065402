import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import InputMask from 'react-input-mask';

import { form_content } from '../../static/_content';

import './form.scss';

const MAIL_URL = `https://admin.bigcityschool.online/wp-json/contact-form-7/v1/contact-forms/5/feedback`;

const Form = () => {
  const navigate = useNavigate();

  const onSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    try {
      const response = await axios.post(MAIL_URL, data);

      if (response.status === 200) {
        toast.success(response.data.message);
        event.target.reset();
        navigate('/thank-you');
      }
    } catch (error) {
      toast.error('Ooops! Something went wrong! Please, try again!');
      console.log(error);
    }
  };

  return (
    <div className="form-wrapper">
      <form onSubmit={onSubmit}>
        <input required type="text" placeholder={form_content.first_name} name="firstName" />
        <input required type="text" placeholder={form_content.last_name} name="lastName" />
        <input required type="email" placeholder={form_content.email} name="email" />
        <InputMask mask="(999) 999 9999" maskChar="" name="phoneNumber" placeholder={form_content.phone} />
        <input required type="text" placeholder={form_content.city} name="city" />

        <input type="submit" value={form_content.button} className="button" />
      </form>

      <div className="data-protection-label">
        <svg aria-hidden="true" viewBox="0 0 24 24">
          <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z" />
        </svg>

        <p>{form_content.data_protected}</p>
      </div>
    </div>
  );
};

export default Form;
