import { lazy } from 'react';
// import { toast } from 'react-toastify';
import useFetch from 'react-fetch-hook';

import { ADMIN_URL } from '../App';

import { Layout } from '../components';

import { Hero } from '../sections';

const WhyWeLazy = lazy(() => import('../sections/WhyWe/WhyWe'));
const AboutLazy = lazy(() => import('../sections/About/About'));
const ModulesLazy = lazy(() => import('../sections/Modules/Modules'));
const TrustLazy = lazy(() => import('../sections/Trust/Trust'));
const CasesLazy = lazy(() => import('../sections/Cases/Cases'));
const PricingLazy = lazy(() => import('../sections/Pricing/Pricing'));
const AfterSchoolLazy = lazy(() => import('../sections/AfterSchool/AfterSchool'));
const GalleryLazy = lazy(() => import('../sections/Gallery/Gallery'));

const Home = ({ phone, email, address, logos, setModal, modal }) => {
  const { isLoading, data, error } = useFetch(`${ADMIN_URL}/pages?slug=home-page`);

  if (isLoading || error) return;

  return (
    <Layout {...{ phone, email, address, logos, setModal, modal }}>
      <Hero data={data[0]?.acf.hero_section} setModal={setModal} />
      <WhyWeLazy data={data[0]?.acf.why_we} />
      <AboutLazy data={data[0]?.acf.about_school} />
      <ModulesLazy data={data[0]?.acf.modules} />
      <TrustLazy data={data[0]?.acf.why_trust_us} />
      <CasesLazy data={data[0]?.acf.cases} />
      <PricingLazy data={data[0]?.acf.pricing} />
      <AfterSchoolLazy data={data[0]?.acf.after_school} />
      <GalleryLazy data={data[0]?.acf.gallery} />
    </Layout>
  );
};

export default Home;
