import './trust.scss';

const Trust = ({ data }) => (
  <section className="trust">
    <div className="container">
      <h2 className="section-title">{data.title}</h2>

      <div className="trust__content" dangerouslySetInnerHTML={{ __html: data.description }} />
    </div>
  </section>
);

export default Trust;
