import { ImageGroup, Image } from 'react-fullscreen-image';

import './gallery.scss';

const Gallery = ({ data }) => (
  <section className="gallery">
    <div className="container">
      <h2 className="section-title">{data?.title}</h2>

      <ImageGroup>
        <ul className="images">
          {data.photos.map((item, index) => (
            <li key={index}>
              <Image
                src={item.url}
                alt={item.alt}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  height: '100%',
                  width: '100%',
                  objectFit: 'cover',
                }}
              />
            </li>
          ))}
        </ul>
      </ImageGroup>
    </div>
  </section>
);

export default Gallery;
