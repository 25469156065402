import { Layout } from '../components';

const ThankYou = ({ phone, email, address, logos, setModal, modal }) => {
  return (
    <Layout {...{ phone, email, address, logos, setModal, modal }}>
      <section className="thank-you">
        <div className="title">Thank you!</div>
        <div className="subtitle">We’ll respond to your request shortly!</div>

        <p>
          Big City has the best students! We’re so grateful for the trust you’ve placed in us. We look forward to help
          you grow!
        </p>
      </section>
    </Layout>
  );
};

export default ThankYou;
