import { Form } from '../../components';

import './whyWe.scss';

const WhyWe = ({ data }) => (
  <section className="why-we">
    <div className="container">
      <div className="why-we__inner">
        <h2 className="section-title">{data?.title}</h2>

        <div className="why-we__content">
          {data?.reasons.map((el, index) => (
            <div className="why-we__content--card" key={index}>
              <div className="header">
                <div className="icon">
                  <img src={el.icon} alt={el.title} />
                </div>

                <h4>{el.title}</h4>
              </div>

              <p>{el.description}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="why-we__form">
        <Form />
      </div>
    </div>
  </section>
);

export default WhyWe;
