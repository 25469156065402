import { useState, useEffect, Fragment } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Header, Footer, Modal } from '../';

import './layout.scss';

const Layout = ({ phone, email, address, logos, setModal, modal, children }) => {
  const [mobileButtons, setMobileButtons] = useState(false);
  const [headerShadow, setHeaderShadow] = useState(false);

  const handleScroll = () => {
    setMobileButtons(window.scrollY > 250);
    setHeaderShadow(window.scrollY > 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Fragment>
      <Header shadow={headerShadow} phone={phone} logo={logos.header} setModal={setModal} />
      <main>{children}</main>
      <Footer phone={phone} email={email} address={address} logo={logos.footer} />

      <ToastContainer />

      <div className={`mobile-cta-buttons ${mobileButtons ? 'on' : 'off'}`}>
        <a href={`tel:${phone}`}>{phone}</a>

        <button onClick={() => setModal(true)}>Хочу присоединиться</button>
      </div>

      {modal && <Modal setModal={setModal} />}
    </Fragment>
  );
};

export default Layout;
