import './afterSchool.scss';

const AfterSchool = ({ data }) => (
  <section className="after-school">
    <div className="container">
      <div className="after-school__inner">
        <div className="after-school__content">
          <h2 className="section-title">{data.title}</h2>

          <div dangerouslySetInnerHTML={{ __html: data.description }} />
        </div>

        <div className="image" style={{ backgroundImage: `url('${data.image}')` }} />
      </div>
    </div>
  </section>
);

export default AfterSchool;
