import './about.scss';

const About = ({ data }) => (
  <section className="about">
    <div className="container">
      {data?.block.map((el, index) => (
        <div className="about__block" key={index}>
          <h2 className="section-title">{el.title}</h2>

          <div className="content">
            <div dangerouslySetInnerHTML={{ __html: el.description }} />

            <div className="image" style={{ backgroundImage: `url('${el.image}')` }} />
          </div>
        </div>
      ))}
    </div>
  </section>
);

export default About;
