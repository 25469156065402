import { Link, NavLink } from 'react-router-dom';

import { header_content } from '../../static/_content';

import './header.scss';

const Header = ({ shadow, phone, logo, setModal }) => (
  <header className={`page-header ${shadow && 'shadow'}`}>
    <TopLine phone={phone} />

    <ActionLine phone={phone} logo={logo} setModal={setModal} />
  </header>
);

export default Header;

const TopLine = ({ phone }) => (
  <div className="top-line">
    <div className="container">
      <p>{header_content.top_line}</p>

      {phone && <Link to={`tel:${phone}`}>{phone}</Link>}
    </div>
  </div>
);

const ActionLine = ({ phone, logo, setModal }) => (
  <div className="action-line">
    <div className="container">
      <NavLink to="/" className="action-line__logo">
        <img src={logo} alt={header_content.logo_alt} />
      </NavLink>

      <div className="action-line__links">
        {phone && <Link to={`tel:${phone}`}>{phone}</Link>}

        <button className="button blue" onClick={() => setModal(true)}>
          {header_content.cta_button}
        </button>
      </div>
    </div>
  </div>
);
