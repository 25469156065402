import {
  // useState,
  Fragment,
} from 'react';
import { pricing_content } from '../../static/_content';

import './pricing.scss';

const Pricing = ({ data }) => {
  // const [baseModal, setBaseModal] = useState(false);
  // const [baseDepositModal, setBaseDepositModal] = useState(false);
  // const [practiceModal, setPracticeModal] = useState(false);
  // const [practiceDepositModal, setPracticeDepositModal] = useState(false);

  const searchParams = window.location.search.split('?')[1];

  return (
    <Fragment>
      <section className="pricing" id="pricing">
        <div className="container">
          <h2 className="section-title">{data.title}</h2>

          <div className="pricing__rates">
            {data.rates.map((el, index) => (
              <div className="pricing__rates--item" key={index}>
                <h4>{el.title}</h4>

                <ul className="includes">
                  {el.includes.map((point, index) => (
                    <li key={index}>{point.point}</li>
                  ))}
                </ul>

                <p>{pricing_content.additional}</p>

                {el.additional && (
                  <ul className="additional">
                    {el.additional.map((point, index) => (
                      <li key={index}>{point.point}</li>
                    ))}
                  </ul>
                )}

                <div className="price">
                  {Number(el.prices.discount) > 0 && (
                    <p className="price-without-discount">${Number(el.prices.price).toFixed(2)}</p>
                  )}
                  {searchParams === 'withDiscount' && (
                    <p className="price-without-discount">${Number(el.prices.price).toFixed(2)}</p>
                  )}
                  <p className="final-price">
                    $
                    {Number(el.prices.discount) === 0
                      ? searchParams === 'withDiscount'
                        ? Number(el.prices.price - 400).toFixed(2)
                        : Number(el.prices.price).toFixed(2)
                      : Number(el.prices.price - el.prices.discount).toFixed(2)}
                  </p>
                </div>

                {/* <div className="links">
                  <button onClick={el.title === 'Базовый' ? () => setBaseModal(true) : () => setPracticeModal(true)}>
                    Оплатить
                  </button>

                  <button
                    onClick={
                      el.title === 'Базовый' ? () => setBaseDepositModal(true) : () => setPracticeDepositModal(true)
                    }
                  >
                    Депозит
                  </button>
                </div> */}

                <div className="links">
                  <a
                    href={searchParams === 'withDiscount' ? el.discount_link : el.link}
                    target="_blank"
                    rel="noReferrer"
                  >
                    {pricing_content.pay_full}
                  </a>
                  {/* <a href={el.deposit_link} target="_blank" rel="noReferrer">
                    {pricing_content.deposit}
                    <br />
                    {pricing_content.deposit_amount}
                  </a> */}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* {baseModal && (
        <div className="modal base">
          <div className="overlay" onClick={() => setBaseModal(false)} />

          <div className="modal__inner">
            <div className="close-btn" onClick={() => setBaseModal(false)} />
            <form
              id="ltForm822596"
              action="https://bigcityschool-online.com/pl/lite/block-public/process-html?id=1858452650"
              method="post"
              data-open-new-window="0"
              className="widget-form"
            >
              <input type="hidden" name="formParams[setted_offer_id]" />
              <br />
              <input type="text" maxLength="60" placeholder="Введите ваше имя" name="formParams[full_name]" required />
              <br />
              <input type="text" maxLength="60" placeholder="Введите ваш эл. адрес" name="formParams[email]" required />
              <br />
              <input type="text" maxLength="60" placeholder="Введите ваш телефон" name="formParams[phone]" required />
              <br />

              <button
                type="submit"
                id="button2429429"
                onClick={() => {
                  if (window['btnprs650893b56f7f3']) {
                    return false;
                  }
                  window['btnprs650893b56f7f3'] = true;
                  setTimeout(function () {
                    window['btnprs650893b56f7f3'] = false;
                  }, 6000);
                  return true;
                }}
              >
                ОФОРМИТЬ ЗАКАЗ
              </button>
              <br />

              <input
                type="hidden"
                id="990449650893b56926a"
                name="_gcinternalformhelper"
                className="gcinternalform_helper"
                value=""
              />

              <input
                type="hidden"
                id="990449650893b56926aref"
                name="_gcinternalformhelper_ref"
                className="gcinternalform_helper_ref"
                value=""
              />

              <input type="hidden" name="requestTime" value="1695060917" />

              <input type="hidden" name="requestSimpleSign" value="1cd40049f969cf666419d0ec86bd5fee" />
              <input type="hidden" name="isHtmlWidget" value="1" />
            </form>
            <span id="gccounterImgContainer" />
          </div>
        </div>
      )} */}

      {/* {baseDepositModal && (
        <div className="modal base">
          <div className="overlay" onClick={() => setBaseDepositModal(false)} />

          <div className="modal__inner">
            <div className="close-btn" onClick={() => setBaseDepositModal(false)} />
            <form
              id="ltForm2121276"
              action="https://bigcityschool-online.com/pl/lite/block-public/process-html?id=1872023221"
              method="post"
              data-open-new-window="0"
              className="widget-form"
            >
              <input type="hidden" name="formParams[setted_offer_id]" />
              <br />
              <input type="text" maxLength="60" placeholder="Введите ваше имя" name="formParams[full_name]" />
              <br />
              <input type="text" maxLength="60" placeholder="Введите ваш эл. адрес" name="formParams[email]" />
              <br />
              <input type="text" maxLength="60" placeholder="Введите ваш телефон" name="formParams[phone]" />
              <br />
              <button
                type="submit"
                id="button750098"
                onClick={() => {
                  if (window['btnprs6508d62b23b85']) {
                    return false;
                  }
                  window['btnprs6508d62b23b85'] = true;
                  setTimeout(function () {
                    window['btnprs6508d62b23b85'] = false;
                  }, 6000);
                  return true;
                }}
              >
                ОФОРМИТЬ ЗАКАЗ
              </button>
              <br />
              <input
                type="hidden"
                id="9985276508d62b20236"
                name="_gcinternalformhelper"
                className="gcinternalform_helper"
                value=""
              />
              <input
                type="hidden"
                id="9985276508d62b20236ref"
                name="_gcinternalformhelper_ref"
                className="gcinternalform_helper_ref"
                value=""
              />
              <input type="hidden" name="requestTime" value="1695077931" />
              <input type="hidden" name="requestSimpleSign" value="dcb3772a3b68fa96d8c3156c1c134435" />
              <input type="hidden" name="isHtmlWidget" value="1" />
            </form>
            <span id="gccounterImgContainer" />
          </div>
        </div>
      )} */}

      {/* {practiceModal && (
        <div className="modal practice">
          <div className="overlay" onClick={() => setPracticeModal(false)} />

          <div className="modal__inner">
            <div className="close-btn" onClick={() => setPracticeModal(false)} />
            <form
              id="ltForm8912890"
              action="https://bigcityschool-online.com/pl/lite/block-public/process-html?id=1858518295"
              method="post"
              data-open-new-window="0"
              className="widget-form"
            >
              <input type="hidden" name="formParams[setted_offer_id]" />
              <br />
              <input type="text" maxLength="60" placeholder="Введите ваше имя" name="formParams[full_name]" required />
              <br />
              <input type="text" maxLength="60" placeholder="Введите ваш эл. адрес" name="formParams[email]" required />
              <br />
              <input type="text" maxLength="60" placeholder="Введите ваш телефон" name="formParams[phone]" required />
              <br />
              <button
                type="submit"
                id="button9002417"
                onClick={() => {
                  if (window['btnprs6508997d4c95a']) {
                    return false;
                  }
                  window['btnprs6508997d4c95a'] = true;
                  setTimeout(function () {
                    window['btnprs6508997d4c95a'] = false;
                  }, 6000);
                  return true;
                }}
              >
                ОФОРМИТЬ ЗАКАЗ
              </button>
              <br />
              <input
                type="hidden"
                id="9904746508997d47c30"
                name="_gcinternalformhelper"
                className="gcinternalform_helper"
                value=""
              />
              <input
                type="hidden"
                id="9904746508997d47c30ref"
                name="_gcinternalformhelper_ref"
                className="gcinternalform_helper_ref"
                value=""
              />
              <input type="hidden" name="requestTime" value="1695062397" />
              <input type="hidden" name="requestSimpleSign" value="513ab8aa7e6ae2c4e040bc26c87e527d" />
              <input type="hidden" name="isHtmlWidget" value="1" />
            </form>
            <span id="gccounterImgContainer" />
          </div>
        </div>
      )} */}

      {/* {practiceDepositModal && (
        <div className="modal practice">
          <div className="overlay" onClick={() => setPracticeDepositModal(false)} />

          <div className="modal__inner">
            <div className="close-btn" onClick={() => setPracticeDepositModal(false)} />
            <form
              id="ltForm9117491"
              action="https://bigcityschool-online.com/pl/lite/block-public/process-html?id=1872022253"
              method="post"
              data-open-new-window="0"
              className="widget-form"
            >
              <input type="hidden" name="formParams[setted_offer_id]" />
              <br />
              <input type="text" maxLength="60" placeholder="Введите ваше имя" name="formParams[full_name]" />
              <br />
              <input type="text" maxLength="60" placeholder="Введите ваш эл. адрес" name="formParams[email]" />
              <br />
              <input type="text" maxLength="60" placeholder="Введите ваш телефон" name="formParams[phone]" />
              <br />
              <button
                type="submit"
                id="button1455156"
                onClick={() => {
                  if (window['btnprs6508d5c237ae1']) {
                    return false;
                  }
                  window['btnprs6508d5c237ae1'] = true;
                  setTimeout(function () {
                    window['btnprs6508d5c237ae1'] = false;
                  }, 6000);
                  return true;
                }}
              >
                ОФОРМИТЬ ЗАКАЗ
              </button>
              <br />
              <input
                type="hidden"
                id="9985246508d5c230def"
                name="_gcinternalformhelper"
                className="gcinternalform_helper"
                value=""
              />
              <input
                type="hidden"
                id="9985246508d5c230defref"
                name="_gcinternalformhelper_ref"
                className="gcinternalform_helper_ref"
                value=""
              />
              <input type="hidden" name="requestTime" value="1695077826" />
              <input type="hidden" name="requestSimpleSign" value="03e36eddb9ff5a1d4c0a8da631a0ca9e" />
              <input type="hidden" name="isHtmlWidget" value="1" />
            </form>
            <span id="gccounterImgContainer" />
          </div>
        </div>
      )} */}
    </Fragment>
  );
};

export default Pricing;
