import Slider from 'react-slick';

import './cases.scss';

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const Cases = ({ data }) => (
  <section className="cases">
    <div className="container">
      <h2 className="section-title">{data.title}</h2>

      <Slider className="cases__list" {...sliderSettings}>
        {data?.links.map((el, index) => (
          <div key={index} className="case-card">
            <iframe
              src={`https://www.youtube.com/embed/${el.url.split('=')[1]}`}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title={el.name}
            />
          </div>
        ))}
      </Slider>
    </div>
  </section>
);

export default Cases;
