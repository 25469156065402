import { Form } from '../';

import './modal.scss';

const Modal = ({ setModal }) => {
  return (
    <div className="modal">
      <div className="overlay" onClick={() => setModal(false)} />

      <div className="modal__inner">
        <div className="close-btn" onClick={() => setModal(false)} />
        <Form />
      </div>
    </div>
  );
};

export default Modal;
