// import { toast } from 'react-toastify';
import useFetch from 'react-fetch-hook';

import { ADMIN_URL } from '../App';

import { Layout } from '../components';

const Terms = ({ phone, email, address, logos, setModal, modal }) => {
  const { isLoading, data, error } = useFetch(`${ADMIN_URL}/pages?slug=terms`);

  if (isLoading || error) return;

  return (
    <Layout {...{ phone, email, address, logos, setModal, modal }}>
      <div className="legal-info">
        <div className="container" dangerouslySetInnerHTML={{ __html: data[0].content.rendered }} />
      </div>
    </Layout>
  );
};

export default Terms;
