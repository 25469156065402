import './modules.scss';

const Modules = ({ data }) => (
  <section className="modules">
    <div className="container">
      <h2 className="section-title">{data.title}</h2>

      <div className="modules__list">
        {data.module.map((el, index) => (
          <div className="modules__list--item" key={index}>
            <p>{el.title.split('-')[0]}</p>
            <p>{el.title.split('-')[1]}</p>
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default Modules;
