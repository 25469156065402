export const header_content = {
  logo_alt: 'Big City Appliance Repair',
  top_line: 'Стань мастером по ремонту бытовой техники!',
  cta_button: 'Хочу присоединиться',
};

export const hero_content = {
  subtitle: 'Стань мастером по ремонту бытовой техники и начни зарабатывать $6,000 - $12,000 в месяц!',
  cta_button: 'Заполнить заявку',
  scroll_button: 'Купить курс',
};

export const form_content = {
  first_name: 'Имя',
  last_name: 'Фамилия',
  email: 'Email',
  phone: 'Телефон',
  city: 'Город',
  button: 'Отправить',
  data_protected: 'Данные защищены и конфиденциальны',
};

export const pricing_content = {
  additional: 'Дополнительно',
  pay_full: 'Оплатить полностью',
  deposit: 'Внести депозит',
  deposit_amount: '(Депозит $499)',
};
